import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import {
  Layout,
  SEO,
  Typography,
  GridCell,
  GridMainContent,
  Wrapper,
  InfoBlock,
  Button,
  Footer,
  Info,
} from "../components"
import { breakpoints } from "../styles/breakpoints"
import MainGraphicSvg from "../assets/main-graphic.svg"
import OurPeopleSVG from "../assets/our-people.svg"
import OurProcessesSVG from "../assets/our-processes.svg"
import OurProjectsSVG from "../assets/our-projects.svg"

const CenteredTitle = styled(Typography)`
  text-align: center;

  @media (max-width: ${breakpoints.PHONE}) {
    display: block;
  }
`

const SectionTitle = styled(props => <Typography use="headline5" {...props} />)`
  margin-left: var(--gutter-3);
`

const MainGraphic = styled(MainGraphicSvg)`
  width: 80%;
  height: auto;
  margin: auto;
  display: block;

  @media (max-width: ${breakpoints.PHONE}) {
    width: 65%;
  }
`

const SectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--gutter-3);
`

const SectionGridCell = styled(props => (
  <GridCell desktop={4} tablet={8} phone={4} {...props} />
))`
  margin-bottom: var(--mdc-layout-grid-margin-desktop);

  &:last-child {
    margin-bottom: 0;
  }
`

const GridCellCenteredContents = styled(GridCell)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const PrimaryCallToAction = styled(Button)`
  margin-top: var(--gutter-3);
`

const IndexPage = () => (
  <Layout page="/">
    <SEO title="Home" />

    <Info
      heading="Welcome to incept5"
      subHeading="We deliver custom applications, and provide consulting which
        is grounded in reality and provides real client benefits.
        We’re here to help our clients create organisational and
        digital transformations which will improve their companies,
        their industries, and the world."
      image={<MainGraphic />}
    />

    <Wrapper>
      <GridMainContent>
        <SectionGridCell>
          <SectionTitleContainer>
            <OurProjectsSVG />
            <SectionTitle>Our Projects</SectionTitle>
          </SectionTitleContainer>

          <Typography use="body1">
            We thrive on big, complicated, pressing problems. We have been
            battle hardened in areas such as Investment Banking, Insurance,
            Healthcare, Telecommunications, Government, Electronic Commerce, and
            Payment Processing. With over 25 years experience operating in these
            domains and others, we have witnessed and been part of huge
            transformations.
          </Typography>
        </SectionGridCell>

        <SectionGridCell>
          <SectionTitleContainer>
            <OurProcessesSVG />
            <SectionTitle>Our Processes</SectionTitle>
          </SectionTitleContainer>

          <Typography use="body1">
            We re-shape ourselves to help our clients achieve their objectives.
            Collaboration is key, and we strive to be flexible, lean, and agile.
            We can drive projects, or be driven as part of a larger initiative.
            We draw upon our experience with best-in-class practices, patterns,
            frameworks, and platforms. We can work remote, on-site, on-shore, or
            off-shore.
          </Typography>
        </SectionGridCell>

        <SectionGridCell>
          <SectionTitleContainer>
            <OurPeopleSVG />
            <SectionTitle>Our People</SectionTitle>
          </SectionTitleContainer>

          <Typography use="body1">
            We pride ourselves on the breadth and depth of our experience, and
            our ability to innovate. Everyone has decades of experience in their
            domain, and have worked together as a group for many years across
            many projects. We offer a broad spectrum of skills :
            Product/Project/Team Management, Digital Architecture, Back End
            Engineering, UI/UX Design, Front End Engineering, Quality Assurance,
            and DevOps.
          </Typography>
        </SectionGridCell>
      </GridMainContent>
    </Wrapper>

    <InfoBlock>
      <Wrapper>
        <GridMainContent>
          <GridCellCenteredContents span={12}>
            <CenteredTitle use="headline2">Want to know more?</CenteredTitle>

            <PrimaryCallToAction
              label="Read our whitepapers"
              size="large"
              onClick={() => navigate("/whitepaper")}
            />
            <PrimaryCallToAction
              label="Read our blog"
              size="large"
              onClick={() => navigate("/blog")}
            />
          </GridCellCenteredContents>
        </GridMainContent>
      </Wrapper>
    </InfoBlock>

    <Footer />
  </Layout>
)

export default IndexPage
